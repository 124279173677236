var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre del plan","outlined":"","rules":_vm.rules.required.concat(
                  [_vm.rules.maxLength(_vm.planNombre, 50)],
                  [_vm.rules.requiredTrim(_vm.planNombre)]
                )},model:{value:(_vm.planNombre),callback:function ($$v) {_vm.planNombre=$$v},expression:"planNombre"}})],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre en credencial","outlined":"","rules":_vm.rules.required.concat(
                  [_vm.rules.maxLength(_vm.nombreCred, 50)],
                  [_vm.rules.requiredTrim(_vm.nombreCred)]
                )},model:{value:(_vm.nombreCred),callback:function ($$v) {_vm.nombreCred=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreCred"}})],1),_c('v-col',{staticClass:"pb-0 py-0 my-2",attrs:{"cols":"1","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text"},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon)+" ")])]}}])},[_c('span',[_vm._v(" Alias para mostrar nombre alternativo en credenciales, sitio web autorizaciones y consulta afiliados. ")])])],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia de venta desde","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.planVigVentaDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.planVigVentaHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaVentaDesde = _vm.parseDateToIso(_vm.planVigVentaDesde)}},model:{value:(_vm.planVigVentaDesde),callback:function ($$v) {_vm.planVigVentaDesde=$$v},expression:"planVigVentaDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaVentaDesde),callback:function ($$v) {_vm.menuFechaVentaDesde=$$v},expression:"menuFechaVentaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.planVigVentaDesde = _vm.formatDate(_vm.fechaVentaDesde)},"input":function($event){_vm.menuFechaVentaDesde = false}},model:{value:(_vm.fechaVentaDesde),callback:function ($$v) {_vm.fechaVentaDesde=$$v},expression:"fechaVentaDesde"}})],1)],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia de venta hasta","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.planVigVentaDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.planVigVentaHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaVentaHasta = _vm.parseDateToIso(_vm.planVigVentaHasta)}},model:{value:(_vm.planVigVentaHasta),callback:function ($$v) {_vm.planVigVentaHasta=$$v},expression:"planVigVentaHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaVentaHasta),callback:function ($$v) {_vm.menuFechaVentaHasta=$$v},expression:"menuFechaVentaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.planVigVentaHasta = _vm.formatDate(_vm.fechaVentaHasta)},"input":function($event){_vm.menuFechaVentaHasta = false}},model:{value:(_vm.fechaVentaHasta),callback:function ($$v) {_vm.fechaVentaHasta=$$v},expression:"fechaVentaHasta"}})],1)],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"dense":"","label":"Código interno del plan","outlined":"","rules":_vm.rules.positiveNumber.concat(_vm.rules.maxLength(_vm.planCod, 5))},model:{value:(_vm.planCod),callback:function ($$v) {_vm.planCod=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"planCod"}})],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"dense":"","label":"Código SSS (medicina prepaga)","outlined":"","rules":_vm.rules.positiveNumber.concat(
                  _vm.rules.maxLength(_vm.planSssCodigo, 10)
                )},model:{value:(_vm.planSssCodigo),callback:function ($$v) {_vm.planSssCodigo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"planSssCodigo"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.btnIsLoading,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }