<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="6" class="pb-0 py-0">
              <v-text-field
                v-model="planNombre"
                dense
                label="Nombre del plan"
                outlined
                :rules="
                  rules.required.concat(
                    [rules.maxLength(planNombre, 50)],
                    [rules.requiredTrim(planNombre)]
                  )
                "
              >
              </v-text-field>
            </v-col>

            <v-col cols="5" class="pb-0 py-0">
              <v-text-field
                v-model.trim="nombreCred"
                dense
                label="Nombre en credencial"
                outlined
                :rules="
                  rules.required.concat(
                    [rules.maxLength(nombreCred, 50)],
                    [rules.requiredTrim(nombreCred)]
                  )
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" sm="1" md="1" class="pb-0 py-0 my-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="primary--text" v-bind="attrs" v-on="on">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>
                  Alias para mostrar nombre alternativo en credenciales, sitio
                  web autorizaciones y consulta afiliados.
                </span>
              </v-tooltip>
            </v-col>

            <v-col cols="6" class="pb-0 py-0">
              <v-menu
                v-model="menuFechaVentaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="planVigVentaDesde"
                    label="Vigencia de venta desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaVentaDesde = parseDateToIso(planVigVentaDesde)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(planVigVentaDesde)) <=
                          new Date(parseDateToIso(planVigVentaHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVentaDesde"
                  no-title
                  @change="planVigVentaDesde = formatDate(fechaVentaDesde)"
                  @input="menuFechaVentaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Periodo Hasta -->

            <v-col cols="6" class="pb-0 py-0">
              <v-menu
                v-model="menuFechaVentaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="planVigVentaHasta"
                    label="Vigencia de venta hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaVentaHasta = parseDateToIso(planVigVentaHasta)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(planVigVentaDesde)) <=
                          new Date(parseDateToIso(planVigVentaHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaVentaHasta"
                  no-title
                  @change="planVigVentaHasta = formatDate(fechaVentaHasta)"
                  @input="menuFechaVentaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" class="pb-0 py-0">
              <v-text-field
                v-model.trim="planCod"
                dense
                label="Código interno del plan"
                outlined
                v-mask="'#####'"
                :rules="
                  rules.positiveNumber.concat(rules.maxLength(planCod, 5))
                "
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 py-0">
              <v-text-field
                v-model.trim="planSssCodigo"
                dense
                label="Código SSS (medicina prepaga)"
                outlined
                v-mask="'##########'"
                :rules="
                  rules.positiveNumber.concat(
                    rules.maxLength(planSssCodigo, 10)
                  )
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="btnIsLoading"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditPlan",
  directives: { mask },
  props: {
    editPlan: { type: Object }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_PLAN,
    newTitle: enums.titles.NUEVO_PLAN,
    calendarIcon: enums.icons.CALENDAR,
    infoIcon: enums.icons.SNB_INFO,
    btnIsLoading: false,
    rules: rules,
    nombreCred: null,
    planCod: null,
    planSssCodigo: null,
    planNombre: null,
    planVigVentaDesde: null,
    planVigVentaHasta: null,
    menuFechaDesde: false,
    menuFechaVentaDesde: false,
    menuFechaHasta: false,
    menuFechaVentaHasta: false,
    fechaVentaDesde: null,
    fechaVentaHasta: null
  }),
  created() {
    if (this.editPlan) {
      this.setPlan();
    } else {
      this.newPlan();
    }
  },
  methods: {
    ...mapActions({
      nuevoPlan: "configConvenios/nuevoPlan",
      setAlert: "user/setAlert"
    }),

    async setPlan() {
      this.planId = this.editPlan.planId;
      this.planNombre = this.editPlan.planNombre;
      this.nombreCred = this.editPlan.nombreCred;
      this.planCod = this.editPlan.planCod;
      this.planSssCodigo = this.editPlan.planSssCodigo;
      this.planVigVentaDesde = this.editPlan.planVigVentaDesde;
      this.fechaVentaDesde = this.parseDateToIso(
        this.editPlan.planVigVentaDesde
      );
      this.planVigVentaHasta = this.editPlan.planVigVentaHasta;
      this.fechaVentaHasta = this.parseDateToIso(
        this.editPlan.planVigVentaHasta
      );
    },
    async newPlan() {
      this.formEditTitle = this.newTitle;
      this.planBonifica = 0;
    },
    async save() {
      const data = {
        planId: this.planId,
        planNombre: this.planNombre,
        nombreCred: this.nombreCred,
        planCod: this.planCod ? this.planCod : "0",
        planSssCodigo: this.planSssCodigo ? this.planSssCodigo : "0",
        planVigVentaDesde: this.fechaVentaDesde,
        planVigVentaHasta: this.fechaVentaHasta
      };
      this.btnIsLoading = true;
      const res = await this.nuevoPlan(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
        this.btnIsLoading = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
