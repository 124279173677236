<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="6" class="pb-0 py-0">
              <v-text-field
                v-model="planNom"
                dense
                label="Nombre del plan"
                outlined
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0 py-0">
              <v-autocomplete
                class="p-0"
                v-model="convenioSelected"
                :items="convenios"
                label="Obra Social"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
                :multiple="isNew"
                return-object
                @change="editOS"
              >
                <template v-slot:prepend-item v-if="isNew">
                  <v-list-item ripple @click="conveniosToggle()">
                    <v-list-item-action>
                      <v-icon>
                        {{ multiselectIconConvenios }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ convenioSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="pb-0 py-0">
              <v-menu
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vigDesde"
                    label="Vigencia desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(vigDesde)"
                    :rules="rules.required.concat(rules.validDate)"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="vigDesde = formatDate(fechaDesde)"
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" class="pb-0 py-0">
              <v-menu
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="vigHasta"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    clearable
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(vigHasta)"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(vigDesde)) <=
                          new Date(parseDateToIso(vigHasta)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="vigHasta = formatDate(fechaHasta)"
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="6" class="pb-0 py-0">
              <v-autocomplete
                v-model="tipoIvaSelected"
                :items="tiposIva"
                label="Condición de IVA"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="btnIsLoading"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditPlan",
  directives: { mask },
  props: {
    editConfigPlan: { type: Object },
    nombrePlan: { type: String },
    setIdPlan: { type: Number }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_CONFIGURACION_PLAN,
    newTitle: enums.titles.NUEVA_CONFIGURACION_PLAN,
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
    calendarIcon: enums.icons.CALENDAR,
    btnIsLoading: false,
    rules: rules,
    planNom: null,
    planxivaId: null,
    planIdGeclisa: null,
    tIvaNom: null,
    fecha: null,
    vigDesde: null,
    vigHasta: null,
    tiposIva: [],
    convenios: [],
    tipoIvaSelected: null,
    convenioSelected: null,
    setConvenio: [],
    isNew: true,
    menuFechaDesde: false,
    menuFechaHasta: false,
    fechaSelectedDesde: null,
    fechaSelectedHasta: null,
    fechaDesde: null,
    fechaHasta: null,
    filterIdS: [],
    cambioOS: false
  }),
  created() {
    this.setSelects();
    if (this.editConfigPlan != null) {
      this.setPlan();
      this.isNew = false;
    } else {
      this.newPlan();
      this.isNew = true;
    }
  },

  computed: {
    multiselectIconConvenios() {
      if (this.selectAllConvenios) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllConvenios() {
      return (
        this.convenioSelected &&
        this.convenioSelected.length === this.convenios.length
      );
    }
  },
  methods: {
    ...mapActions({
      nuevaConfigPlan: "configConvenios/nuevaConfigPlan",
      getConvenios: "afiliaciones/getConvenios",
      getTiposIva: "configConvenios/getTiposIva",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const tiposIva = await this.getTiposIva();
      this.tiposIva = tiposIva;
      const convenios = await this.getConvenios();
      this.convenios = convenios;
    },
    setPlan() {
      this.planxivaId = this.editConfigPlan.planxivaId;
      this.planId = this.editConfigPlan.planId;
      this.planNom = this.editConfigPlan.planNom;
      this.tipoIvaSelected = this.editConfigPlan.tIvaId;
      this.convenioSelected = this.editConfigPlan.osId;
      this.vigDesde = this.editConfigPlan.vigDesde;
      this.fechaDesde = this.parseDateToIso(this.editConfigPlan.vigDesde);
      this.vigHasta = this.editConfigPlan.vigHasta;
      this.fechaHasta = this.parseDateToIso(this.editConfigPlan.vigHasta);
    },

    newPlan() {
      this.formEditTitle = this.newTitle;
      this.planNom = this.nombrePlan;
      this.planId = this.setIdPlan;
    },
    async save() {
      this.btnIsLoading = true;
      this.setConvenio.push(this.convenioSelected);

      if (this.isNew) {
        this.filterIdS = this.convenioSelected.map(el => el.id);
      } else {
        if (this.cambioOS) {
          this.filterIdS.push(this.convenioSelected.id);
        }
      }

      const data = {
        planxivaId: this.planxivaId ? this.planxivaId : 0,
        osIds: this.cambioOS || this.isNew ? this.filterIdS : this.setConvenio,
        planId: this.planId,
        tIvaId: this.tipoIvaSelected,
        vigDesde: this.fechaDesde,
        vigHasta: this.fechaHasta
      };
      try {
        const res = await this.nuevaConfigPlan(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.filterIdS = null;
          this.btnIsLoading = false;
        }
      } catch (error) {
        this.btnIsLoading = false;
      }
    },
    editOS() {
      this.cambioOS = true;
    },
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) this.convenioSelected = [];
        else this.convenioSelected = this.convenios;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
