var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre del plan","outlined":"","disabled":""},model:{value:(_vm.planNom),callback:function ($$v) {_vm.planNom=$$v},expression:"planNom"}})],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"p-0",attrs:{"items":_vm.convenios,"label":"Obra Social","item-text":"value","item-value":"id","rules":_vm.rules.required,"outlined":"","clearable":"","dense":"","multiple":_vm.isNew,"return-object":""},on:{"change":_vm.editOS},scopedSlots:_vm._u([(_vm.isNew)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.conveniosToggle()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.multiselectIconConvenios)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.convenioSelected.length - 1)+" otros) ")]):_vm._e()]}}],null,true),model:{value:(_vm.convenioSelected),callback:function ($$v) {_vm.convenioSelected=$$v},expression:"convenioSelected"}})],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia desde","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(_vm.rules.validDate),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.vigDesde)}},model:{value:(_vm.vigDesde),callback:function ($$v) {_vm.vigDesde=$$v},expression:"vigDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.vigDesde = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Vigencia hasta","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.vigDesde)) <=
                        new Date(_vm.parseDateToIso(_vm.vigHasta)) ||
                        'Formato incorrecto'
                    ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.vigHasta)}},model:{value:(_vm.vigHasta),callback:function ($$v) {_vm.vigHasta=$$v},expression:"vigHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.vigHasta = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposIva,"label":"Condición de IVA","item-text":"value","item-value":"id","rules":_vm.rules.required,"outlined":"","clearable":"","dense":""},model:{value:(_vm.tipoIvaSelected),callback:function ($$v) {_vm.tipoIvaSelected=$$v},expression:"tipoIvaSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"loading":_vm.btnIsLoading,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }