<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />

        <v-data-table
          :headers="headers"
          :items="filteredItems"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="planId"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canConfig">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalConfiguracion(item)"
                >
                  {{ configIcon }}
                </v-icon>
              </template>
              <span>Configurar</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar plan</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteTopeCuotas(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar plan</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="45%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditPlan
        :editPlan="editPlan"
        @closeAndReload="closeAndReload"
        :planId="planId"
      ></EditPlan>
    </v-dialog>
    <v-dialog
      v-if="openModalConfig"
      v-model="openModalConfig"
      max-width="65%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <ConfigPlan
        :editConfig="editConfig"
        @closeAndReload="closeAndReload"
        :setIdPlan="setIdPlan"
      ></ConfigPlan>
    </v-dialog>

    <v-dialog
      v-if="openModalPlanes"
      v-model="openModalPlanes"
      max-width="35%"
      @keydown.esc="openModalPlanes = false"
      persistent
    >
    </v-dialog>
    <DeleteDialog
      :isLoading="isLoading"
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditPlan from "@/components/modules/convenios/configuracion/EditPlan.vue";
import ConfigPlan from "@/components/modules/convenios/configuracion/ConfigPlan.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditPlan,
    ConfigPlan,
    GoBackBtn,
    Ayuda
  },
  name: "Planes",
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    configIcon: enums.icons.SETTINGS,
    isFormValid: false,
    search: "",
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.PLANES,
    showDeleteModal: false,
    idToDelete: null,
    editPlan: {},
    editConfig: {},
    planId: null,
    planes: [],
    routeToGo: "",
    title: enums.titles.PLANES,
    titleDelete: enums.titles.DELETE_PLAN,
    rules: rules,
    headers: [
      {
        text: "Nombre del plan",
        value: "planNombre",
        sortable: false,
        align: "start"
      },
      {
        text: "Nombre en credencial",
        value: "nombreCred",
        sortable: false,
        align: "start"
      },
      {
        text: "Vigencia de venta desde",
        value: "planVigVentaDesde",
        sortable: false
      },
      {
        text: "Vigencia de venta hasta",
        value: "planVigVentaHasta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canConfig: true,
    openModalPlanes: false,
    openModalEdit: false,
    openModalConfig: false,
    nombrePlan: null,
    setIdPlan: null
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadPlanes();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  computed: {
    filteredItems() {
      return this.planes.filter(item => item.planId !== 0);
    }
  },
  methods: {
    ...mapActions({
      getPlanes: "configConvenios/getPlanes",
      getConfigPlan: "configConvenios/getConfigPlan",
      deletePlan: "configConvenios/deletePlan",
      getPermisosConfigPlanes: "configConvenios/getPermisosConfigPlanes",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_PLAN:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_PLAN:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_PLAN:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIGURAR_PLAN:
            this.canConfig = true;
            break;
          default:
            break;
        }
      });
    },

    async loadPlanes() {
      this.isLoading = true;
      const data = await this.getPlanes();
      this.planes = data;
      this.isLoading = false;
    },

    deleteTopeCuotas(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.planId;
    },
    async confirmDelete() {
      this.isLoading = true;
      try {
        const response = await this.deletePlan({
          planId: this.idToDelete
        });
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadPlanes();
          this.isLoading = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.isLoading = false;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editPlan = item;
    },

    openModalConfiguracion(item) {
      this.openModalConfig = true;
      this.editConfig = item;
      this.nombrePlan = item.planNombre;
      this.setIdPlan = item.planId;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.openModalConfig = false;
      this.loadPlanes();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
