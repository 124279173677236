<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ title }}: {{ nombrePlan }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="configs"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          item-key="planxivaId"
          sort-by="osNom"
          :sort-asc="true"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="4" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar configuración del plan</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteTopeCuotas(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar configuración del plan</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal" class="mr-2"> Cerrar </v-btn>
      </v-card-actions>

      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="60%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditConfigPlan
          :editConfigPlan="editConfigPlan"
          @closeAndReload="closeAndReload"
          :setIdPlan="setIdPlan"
          :nombrePlan="nombrePlan"
        ></EditConfigPlan>
      </v-dialog>
      <DeleteDialog
        :isLoading="isLoading"
        :titleProp="titleDelete"
        :maxWidth="'30%'"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConfigPlan from "@/components/modules/convenios/configuracion/EditConfigPlan.vue";

export default {
  name: "ConfigPlan",
  directives: { mask },
  components: {
    PageHeader,
    DeleteDialog,
    EditConfigPlan
  },
  props: {
    editConfig: { type: Object }
  },
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    isFormValid: false,
    search: "",
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    editConfigPlan: {},
    setIdPlan: "",
    planxivaId: null,
    configs: [],
    nombrePlan: null,
    title: enums.titles.CONFIGURACION_PLANES,
    titleDelete: enums.titles.DELETE_CONFIGURACION_PLAN,
    rules: rules,
    headers: [
      {
        text: "Nombre obra social",
        value: "osNom",
        sortable: false,
        align: "start"
      },

      {
        text: "Vigencia desde",
        value: "vigDesde",
        sortable: false
      },
      {
        text: "Vigencia hasta",
        value: "vigHasta",
        sortable: false
      },
      {
        text: "Tipo de IVA",
        value: "tIvaNom",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false,
    optionCode: enums.webSiteOptions.CONFIGURACION_PLANES
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  mounted() {
    this.setConfigPermisos();
    this.loadConfigs();
    this.nombrePlan = this.editConfig.planNombre;
    this.setIdPlan = this.editConfig.planId;
  },

  methods: {
    ...mapActions({
      getConfigPlan: "configConvenios/getConfigPlan",
      getPermisosConfigPlanes: "configConvenios/getPermisosConfigPlanes",
      deleteConfigPlan: "configConvenios/deleteConfigPlan",
      setAlert: "user/setAlert"
    }),
    async setConfigPermisos() {
      const permisos = await this.getPermisosConfigPlanes({
        optionCode: this.optionCode
      });
      this.canCreate = permisos.canCreate;
      this.canEdit = permisos.canEdit;
      this.canDelete = permisos.canDelete;
    },
    async loadConfigs() {
      this.isLoading = true;
      const data = await this.getConfigPlan(this.editConfig.planId);
      this.configs = data;
      this.isLoading = false;
    },

    deleteTopeCuotas(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.planxivaId;
    },
    async confirmDelete() {
      this.isLoading = true;
      try {
        const response = await this.deleteConfigPlan({
          planxivaId: this.idToDelete
        });
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.loadConfigs();
          this.isLoading = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.isLoading = false;
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editConfigPlan = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadConfigs();
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
